$(document).ready(
  ()=> {
    const tagList = ['#banner', '#service', '#tech', '#apply','#partner']
    const topMenuHeight = 55
    const scrollItems = []
    tagList.map(item => {
      // console.log($(item).offset().top);
      scrollItems.push(($(item).offset().top))
    })
    console.log(scrollItems);
    $("div.lazy").lazyload({
      effect : "fadeIn"
    });
    $('.linkHref a').click((e)=>{
      // 1.跳转
      const jumpTarget = $(e.target).attr('self-href')
      const getScrollTop = $(jumpTarget).offset().top - topMenuHeight
      $('body, html').animate({ scrollTop: getScrollTop }, 500);
      // 2.active类名赋予
      $('.linkHref a').removeClass('active')
      $(e.target).addClass('active')
    })
    // 监听滚动
    // Bind to scroll
    $(window).scroll(function(){
      // Get container scroll position
      const fromTop = $(this).scrollTop()+topMenuHeight;
      
      let res
      let ind = 4
      const tempScrollItem = JSON.parse(JSON.stringify(scrollItems))
      while(tempScrollItem.length > 0) {
        let last = tempScrollItem.pop()
        console.log(last);
        console.log(tempScrollItem.length);
        if (fromTop > last - 3) {
          res = ind
          break;
        }
        ind--
      }
      $('.linkHref a').removeClass('active')
      $('.linkHref a').eq(res).addClass('active')
    })

    // banner
    console.log($('.owl-1'));
    $('.owl-1').owlCarousel({
			// animateOut: 'fadeOut',
			center: true,
			items: 1,
			loop: true,
			margin: 0,
			smartSpeed: 1500,
			dots: true,
	    autoplay: true,
      lazyLoad: true,
	    pauseOnHover: false,
      nav: true,
      navText: ['<span class="icon-arrow_back">', '<span class="icon-arrow_forward">'],
      navigation : true,
	    onDragged: function(event) {
	    	console.log('event : ',event.relatedTarget['_drag']['direction'])
	    	if ( event.relatedTarget['_drag']['direction'] == 'left') {
	    		$('.slide-one-item-alt-text').trigger('next.owl.carousel');
	    	} else {
	    		$('.slide-one-item-alt-text').trigger('prev.owl.carousel');
	    	}
	    }
		})
    $('.owl-2').owlCarousel({
			// animateOut: 'fadeOut',
			center: true,
			items: 1,
			loop: true,
			margin: 10,
			smartSpeed: 1500,
			dots: true,
	    autoplay: false,
      lazyLoad: true,
	    pauseOnHover: false,
      nav: true,
      navText: ['<span class="icon-arrow_back_2">', '<span class="icon-arrow_forward_2">'],
      navigation : true,
	    onDragged: function(event) {
	    	console.log('event : ',event.relatedTarget['_drag']['direction'])
	    	if ( event.relatedTarget['_drag']['direction'] == 'left') {
	    		$('.slide-one-item-alt-text').trigger('next.owl.carousel');
	    	} else {
	    		$('.slide-one-item-alt-text').trigger('prev.owl.carousel');
	    	}
	    }
		})
    $('.owl-3').owlCarousel({
			// animateOut: 'fadeOut',
			center: true,
			items: 1,
			loop: true,
			margin: 10,
			smartSpeed: 1500,
			dots: true,
	    autoplay: false,
	    pauseOnHover: false,
      lazyLoad: true,
      nav: true,
      navText: ['<span class="icon-arrow_back_2">', '<span class="icon-arrow_forward_2">'],
      navigation : true,
	    onDragged: function(event) {
	    	console.log('event : ',event.relatedTarget['_drag']['direction'])
	    	if ( event.relatedTarget['_drag']['direction'] == 'left') {
	    		$('.slide-one-item-alt-text').trigger('next.owl.carousel');
	    	} else {
	    		$('.slide-one-item-alt-text').trigger('prev.owl.carousel');
	    	}
	    }
		})
    $('.tabContainer .tab-tit').click((e) => {
      const tar = $(e.target).attr('self-index');
      console.log(tar);
      $('.tab-tit').removeClass('active')
      $(e.target).addClass('active')
      $('.owl-2').removeClass('active')
      $('.owl-3').removeClass('active')
      $(tar).addClass('active')
    })
    $('#client-carousel').owlCarousel({
      loop: true,
      autoplay: true,
      autoplayTimeout: 2500,
      autoplaySpeed: 2050,
	    pauseOnHover: false,
      lazyLoad: true,
      items:3,
      stagePadding: 36
    });
  }
)